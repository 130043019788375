<template>
  <section class="hero is-primary is-fullheight login-principal-container">
    <!-- Hero head: will stick at the top -->
    <div class="hero-head">
      <nav class="navbar">
        <div class="container">
          <div class="navbar-brand">
            <a class="navbar-item" href="https://www.coconutcontrol.com/">
              <img src="@/assets/img/logo-final.png" />
            </a>
            <span class="navbar-burger burger" data-target="navbarMenuHeroA">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>
          <div id="navbarMenuHeroA" class="navbar-menu">
            <div class="navbar-end">
              <a class="navbar-item is-active" @click="openLogin()">
                <b class="change-login-register-action">Iniciar sesión </b>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>

    <!-- Hero content: will be in the middle -->
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="columns is-centered absolut-center">
          <div class="column is-half login-form-container">
            <p class="title" data-testid="login-title">Nueva contraseña</p>
          </div>
          <div class="column is-half login-form-container">
            <b-field
              label="Contraseña"
              class="login-form-field"
              :type="{ 'is-danger': error.password }"
              :message="{ 'La contraseña es incorrecta': error.password }"
            >
              <b-input
                placeholder="contraseña"
                type="password"
                maxlength="35"
                v-model="recovery.password"
                password-reveal
              ></b-input>
            </b-field>

            <b-field
              label="Confirmar contraseña"
              class="login-form-field"
              :type="{ 'is-danger': error.confirmPassword }"
              :message="{
                'La contraseña es incorrecta': error.confirmPassword,
              }"
            >
              <b-input
                placeholder="confirmar contraseña"
                type="password"
                maxlength="35"
                v-model="recovery.confirmPassword"
                password-reveal
              ></b-input>
            </b-field>
          </div>
          <div class="column is-half login-button">
            <b-button class="login-button" @click="login()">Continuar</b-button>
          </div>
        </div>
      </div>
    </div>

    <!-- Hero footer: will stick at the bottom -->
    <div class="hero-foot">
      <nav class="tabs">
        <div class="container">
          <ul>
            <li class="is-active"><a></a></li>
            <li><a>Punto de venta</a></li>
            <li><a>Control de inventario</a></li>
            <li><a>Venta de productos</a></li>
            <li><a>Administración de sucursales</a></li>
            <li><a>Control de pedidos</a></li>
          </ul>
        </div>
      </nav>
    </div>
  </section>
</template>

<script>
import { checkValidationErrors, validateFields } from "../../../utils/fns";
// @ is an alias to /src
export default {
  name: "NewPassword",
  components: {},
  data() {
    return {
      recovery: {
        password: "",
        confirmPassword: "",
        token: "",
      },
      error: {
        password: false,
        confirmPassword: false,
        token: false,
      },
    };
  },
  mounted() {
    this.recovery.token = this.$route.query.token;
  },
  methods: {
    openLogin() {
      this.$router.push({ path: "/Login" });
    },
    async login() {
      this.error = validateFields(this.recovery, this.error);
      if (checkValidationErrors(this.error)) {
        if (this.recovery.password === this.recovery.confirmPassword) {
          const response = await this.$store.dispatch(
            "RESETPASSWORD",
            this.recovery
          );
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se cambió exitosamente la contraseña",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.openLogin();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al cambiar la contraseña",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.error.confirmPassword = true;
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Por favor valide que las contraseñas sean iguales",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } else {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Por favor valide que los datos son correctos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
};
</script>

<style scoped>
@import "../style/Login.css";
@import "../style/Global.css";
</style>
